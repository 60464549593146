const iwoodProjects = [
    {
        value: 'santa-maria-8700',
        name: 'Santa Maria 8700'
    },
    {
        value: 'loma-la-cruz',
        name: 'Loma la Cruz'
    },
    {
        value: 'los-litres',
        name: 'Los Litres'
    },
    {
        value: 'cerro-la-cruz',
        name: 'Cerro la Cruz'
    },
    {
        value: 'piedra-viva',
        name: 'Piedra Viva'
    },
    {
        value: 'canto-del-viento',
        name: 'Canto del Viento'
    },
    {
        value: 'morro-de-zapallar',
        name: 'Morro de Zapallar'
    },
    {
        value: 'vista-peru',
        name: 'Vista Perú'
    },
]

export default iwoodProjects